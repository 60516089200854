body {
  background-color: #b7e6ee;
  font-family: "Raleway", sans-serif;
  margin: 0px;
  position: relative;
}

img {
  width: 100%;
}

h1,
h2,
h3 {
  margin: 5px 0px;
}

p {
  margin: 10px 0px;
  line-height: 1.4;
}

li {
  line-height: 1.5;
}

blockquote {
  line-height: 1.5;
}

#note {
  color: tomato;
}

code {
  color: tomato;
}

pre {
  white-space: pre-wrap;
  word-break: break-word;
  background-color: #eee;
  color: white;
  padding: 20px !important;
  border: none !important;
}

.prettyprint {
  display: block;
  background-color: #333;
}

.seealllink {
  align-self: center;
  border-radius: 15px;
  padding: 10px 50px;
  background-color: #0d656b;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.seealllink a {
  text-decoration: none;
  color: white;
}

.seealllink:active {
  color: teal;
}

.seealllink:hover {
  transform: scale(1.05);
}

/* Main Content */

.maincontent {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.codepen {
  height: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  margin: 1em 0;
  padding: 1em;
}

/* Sticky Nav */

.stickynav {
  top: 0px;
  position: sticky;
  display: flex;
  justify-content: space-between;
  background: white;
  z-index: 20;
}

.stickynavsocials {
  display: flex;
  gap: 20px;
  padding: 10px 0px 10px 20px;
  width: 15%;
}

.socialimg {
  width: 35px;
  display: block;
}

.hamburgermenu {
  /* width: 50px; */
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  padding-right: 20px;
  width: 15%;
}

.disappear {
  display: none;
}

.hamburgerbutton {
  width: 40px;
  height: 4px;
  background: black;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.hamburgerbutton::before,
.hamburgerbutton::after {
  content: "";
  width: 40px;
  height: 4px;
  position: absolute;
  background: black;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.hamburgerbutton::before {
  transform: translate(0px, -12px);
}
.hamburgerbutton::after {
  transform: translate(0px, 12px);
}

.hamburgermenu.open .hamburgerbutton {
  background: transparent;
}

.hamburgermenu.open .hamburgerbutton::before {
  transform: rotate(45deg) translate(0px, 0px);
}

.hamburgermenu.open .hamburgerbutton::after {
  transform: rotate(-45deg) translate(0px, 0px);
}

.mobilemenu {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  opacity: 0;
  height: 0%;
  width: 100%;
  transition: opacity 0.7s ease-in-out;
  gap: 3px;
  z-index: -1;
}

.mobilenavitem {
  width: 15%;
  text-align: center;
  margin-right: 3px;
}

.mobilenavitem h3 {
  background-color: white;
  padding: 8px 16px;
  margin: 0px;
}

.mobilenavitem a {
  color: #0d656b;
  text-decoration: none;
}

#homemobilelink {
  margin-top: 3px;
}

.navmenu {
  display: flex;
  padding: 0px;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.navitem {
  transition: all 0.2s ease-in-out;
  padding: 0px 30px;
}

.navitem:hover {
  color: teal;
  cursor: pointer;
  transform: scale(1.1);
}

.navitem a {
  color: #0d656b;
  text-decoration: none;
}

.navitem:active {
  color: white;
}

/* Hero Banner */

.herobanner {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.heroimage {
  max-width: 80%;
}

/* About Section */

.about {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  padding: 100px 0px 60px 0px;
}

.aboutinfo {
  display: flex;
  width: 50%;
  justify-content: center;
  position: relative;
}

.profilepic {
  margin-top: auto;
  position: absolute;
  top: -60px;
  left: -60px;
}

.profileimage {
  width: 150px;
  border-radius: 50%;
  border: 3px solid white;
}

.profilesummary {
  padding: 5px 20px 5px 120px;
  background: white;
  border-radius: 10px;
}

.aboutbullets {
  margin-bottom: 10px;
}

/* Skills Section */

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0px;
}

.skillstitle {
  text-align: center;
}

.skillslist {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  gap: 20px 20px;
  width: 50%;
  margin: auto;
  padding: 40px 0px 20px 0px;
}

.skillicon {
  text-align: center;
  margin: auto;
  min-width: 12%;
  padding-bottom: 20px;
}

.skillsimage {
  max-width: 50px;
}

/* Latest Blog Section */

.latestblog {
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
}

.blogtitle {
  text-align: center;
}

.blogtitlemobile {
  display: none;
}

.blogdate {
  padding-bottom: 10px;
}

.blogpreviews {
  display: flex;
  justify-content: center;
  width: 75%;
  margin: auto;
  padding: 20px 0px;
}

.blogpreview {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.blogimage {
  border-radius: 10px;
  margin: 10px 0px;
  border: 3px solid black;
}

.seemore {
  align-self: flex-start;
  margin-top: 10px;
  transition: all 0.2s ease-in-out;
}

.seemore a {
  color: #0d656b;
  text-decoration: none;
}

.seemore:hover {
  transform: scale(1.1);
}

.seemore a:active {
  color: #0d656b;
  text-decoration: none;
}

/* Featured Projects Section */

.featuredproject {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
}

.projecttitle {
  text-align: center;
}

.projecttitlemobile {
  display: none;
}

.projectpreviews {
  display: flex;
  justify-content: center;
  width: 75%;
  margin: auto;
  padding: 20px 0px;
  gap: 40px;
}

.projectpreview {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.projectimage {
  border-radius: 10px;
  margin: 10px 0px;
  border: 3px solid black;
}

/* Resume Page */

.resumecontainer {
  background-color: #f2f2f2;
}

.resumebody {
  display: grid;
  width: 75%;
  margin: auto;
  padding-top: 40px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}

.resumebody a {
  color: #0d656b;
}

.resumetop {
  grid-column: 1/3;
}

.resumeleft {
  grid-column: 1/2;
}

.resumeright {
  grid-column: 2/3;
}

.resumebottom {
  grid-column: 1/3;
}

.resumeintro {
  display: flex;
  justify-content: flex-start;
  background-color: white;
  margin: auto;
}

.resumephoto {
  max-width: 150px;
  padding: 20px;
  background-color: #a7dae2;
}

.resumeprofilepic {
  display: block;
}

.resumeintrocopy {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.resumeintrocopy h2 {
  margin-bottom: 0px;
}

.resumelocationemail {
  padding: 10px 0px;
}

.resumesociallinks {
  display: flex;
  gap: 20px;
  padding: 10px 0px 0px 0px;
}

.leftsection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.leftresumeitem {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.leftresumeitem h3,
.rightresumeitem h3,
.rightsection h3,
.resumebottom h3 {
  background-color: #a7dae2;
  padding: 5px;
  margin: 0px;
}

.resumeleft span,
.resumebottom span {
  padding: 10px;
  line-height: 1.5;
}

.rightsection {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.rightsection h4,
.resumebottom h4 {
  background-color: #c4ebea;
  margin: 0px;
  padding: 5px 10px;
}

.skillsicons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  padding: 0px 10px 10px 10px;
}

.resumeskillicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  justify-content: flex-end;
}

.resumeskillicon > img {
  max-width: 45px;
}

.otherskillsbullets {
  padding: 5px 10px;
  line-height: 1.5;
}

.resumebottom {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.experiencesection {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.experiencedates {
  text-align: right;
}

.experiencedescription {
  grid-column: 1/4;
  padding: 10px;
}

/* Portfolio Page & Blog Page */

.portfoliointro,
.blogintro {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: flex-start;
  width: 75%;
  padding-top: 35px;
}

.portfoliocardsbg,
.blogpreviewsbg {
  background: #f2f2f2;
}

.porfoliocardsbg a {
  color: #0d656b;
}

.portfoliocards,
.blogpreviews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px 40px;
  max-width: 75%;
  margin: auto;
  padding: 40px 0px;
  justify-items: center;
}

.portfoliocard,
.blogpreviewcard {
  background-color: white;
  padding: 10px 20px;
}

.portfolioimage,
.blogpreviewimage {
  margin: 10px 0px;
  border-radius: 10px;
  /* border: 3px solid black; */
  max-width: 100%;
}

.portfoliointrocopy,
.blogintrocopy {
  line-height: 1.5;
}

.expandedcard {
  display: none;
  position: fixed;
  background-color: white;
  z-index: 25;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-height: 80%;
  width: 80%;
  max-width: 600px;
  overflow-y: scroll;
}

.closebutton {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  font-size: 30px;
}

.overlay {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 20;
}

/* Individual Blog Pages */

.allblogcontent {
  background-color: #f2f2f2;
}

.blogcontent {
  display: grid;
  width: 75%;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;
  margin: auto;
  margin-top: 40px;
}

.blogwrapper {
  background-color: white;
  padding: 40px;
}

.blogwrapper h2 {
  margin-top: 0px;
}

.backtoallblogs {
  background-color: white;
  padding: 20px;
  height: 1em;
  margin: 0px;
}

.backtoallblogs a {
  color: #0d656b;
  text-decoration: none;
}

.blogwrapper p {
  line-height: 1.7;
  margin-bottom: 20px;
}

.blogwrapper a {
  color: #0d656b;
  text-decoration: none;
}

.blogwrapper h3 {
  margin-top: 20px;
}

.blogauthor {
  margin-bottom: 0px !important;
}

.blogpublished {
  margin-top: 0px;
}

.teamtable {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.teamtableheader {
  background-color: #a7dae2;
  padding: 5px;
}

.teamtablelightgray {
  background-color: rgb(230, 230, 230);
  padding: 5px;
}

.teamtabledarkgray {
  background-color: rgb(193, 193, 193);
  padding: 5px;
}

.customcodetable {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  max-width: 300px;
  margin: auto;
  justify-content: center;
}

blockquote {
  padding: 10px;
  background: #eee;
  border-radius: 15px;
}

blockquote p {
  margin: 5px;
}

/* Footer */

.footer {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}

@media screen and (max-width: 1000px) {
  .resumeleft {
    grid-column: 1/3;
  }
  .resumeright {
    grid-column: 1/3;
  }
  .blogcontent {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 40px;
    width: 90%;
    grid-gap: 20px;
  }
  .backtoallblogs {
    order: 1;
  }
  .blogwrapper {
    order: 2;
  }
}

@media screen and (max-width: 800px) {
  /* General */

  .aboutinfo,
  .skillslist,
  .blogpreviews,
  .projectpreviews {
    width: 90%;
  }

  .skills,
  .latestblog,
  .featuredproject {
    padding: 30px 0px;
  }

  /* Sticky Nav */

  .socialimg {
    width: 25px;
  }

  .hamburgermenu {
    display: flex;
  }

  .mobilenavitem {
    width: 40%;
  }

  /* Hero */

  .heroimage {
    max-width: 100%;
    padding-top: 10px;
  }

  /* Nav */

  .navmenu {
    display: none;
  }

  /* .navmenu {
    width: 90%;
    padding: 0px 10px 10px 10px;
    gap: 10px;
    font-size: 12px;
  } */

  /* About */

  .about {
    padding: 0px 0px 30px 0px;
  }

  .aboutinfo {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: static;
    margin-top: -30px;
  }

  .profilepic {
    position: relative;
    top: 50px;
    left: 0px;
  }

  .profileimage {
    width: 125px;
  }

  .profilesummary {
    padding: 50px 20px 5px 20px;
  }

  /* Skills */

  .skillslist {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    gap: 10px 10px;
    width: 90%;
    margin: auto;
    padding-top: 40px;
  }

  /* Blog */

  .blogtitle {
    display: none;
  }

  .blogtitlemobile {
    text-align: center;
    display: block;
  }

  .otherblogs {
    display: none;
  }

  .blogpreviews {
    padding: 0px;
  }

  /* Games */

  .projecttitlemobile {
    display: block;
    text-align: center;
  }

  .projecttitle {
    display: none;
  }

  .projectpreview {
    width: 90%;
  }

  .otherprojects {
    display: none;
  }

  /*Resume*/

  .resumebody {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .resumephoto {
    max-width: 125px;
    padding: 10px;
    background-color: #a7dae2;
  }

  .resumeintrocopy {
    padding: 10px;
    justify-content: center;
  }

  .resumeintrocopy h2 {
    font-size: 20px;
  }

  .resumeintrocopy h3 {
    font-size: 16px;
  }

  .resumelocationemail {
    padding: 5px 0px;
  }
}
